import getConfig from 'next/config';
import {
  cacheExchange,
  CombinedError,
  createClient,
  errorExchange,
  fetchExchange,
  Operation,
} from 'urql';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

enum GRAPHQL_ERROR {
  FORBIDDEN = 'FORBIDDEN',
}

const urqlClient = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  serverSide?: boolean,
) =>
  createClient({
    url: `${
      serverSide && serverRuntimeConfig.API_ORIGIN
        ? serverRuntimeConfig.API_ORIGIN
        : publicRuntimeConfig.API_ORIGIN
    }/graphql`,
    maskTypename: true,
    requestPolicy: 'network-only',
    exchanges: [
      errorExchange({
        onError(error: CombinedError, operation: Operation) {
          // Handling Forbidden/Not authorized error
          const forbiddenErrorIndex = error?.graphQLErrors.findIndex(
            (err) => err.extensions.code === GRAPHQL_ERROR.FORBIDDEN,
          );
          if (forbiddenErrorIndex !== undefined && forbiddenErrorIndex !== -1) {
            const message =
              operation.kind === 'mutation'
                ? `You don't have access to perform this action`
                : `You don't have access to this resource`;
            enqueueSnackbar(message, { variant: 'error' });

            // Removing the forbidden error from the error list, so it won't be handled again later.
            error.graphQLErrors.splice(forbiddenErrorIndex, 1);
          }
        },
      }),
      cacheExchange,
      fetchExchange,
    ],
    fetchOptions: {
      credentials: 'include',
    },
  });

export default urqlClient;
